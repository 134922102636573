import NestedFormController from "../nested_form_controller"

export default class extends NestedFormController {
  static targets = ["links", "template", "selectbox"];

  add_association(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    const wrapper = document.createElement("div");
    wrapper.innerHTML = content;
    const attendance = document.getElementById('bid_request_event_attributes_target_attendance').value;
    const newContent = wrapper.firstElementChild;
    super.setAttendance(wrapper, "quantity", attendance);
    this.templateTarget.parentNode.insertBefore(newContent, this.templateTarget.nextSibling);
    this.rebindUI();
  }
}