/* eslint-disable no-underscore-dangle */
import { Controller } from "@hotwired/stimulus";
import { Dropdown } from "flowbite";

export default class extends Controller {
  static dropdown;

  toggleDropdown(rowId, show) {
    const targetContainers = document.querySelectorAll(
      `div[data-dropdown-id='timepickerContainer']`,
    );
    targetContainers.forEach((container) => {
      if (show && container.dataset.tsid === rowId) {
        container.classList.remove("tw-hidden");
        container.classList.add("tw-block");
      } else {
        container.classList.remove("tw-block");
        container.classList.add("tw-hidden");
      }
    });
  }

  formatTime(time) {
    // Insert string with colon in 24 hour format 'HH:MM'
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  }

  show_duration_dropdown(triggerAction) {
    const triggerBtn =
      triggerAction.target.type === "button"
        ? triggerAction.target
        : triggerAction.target.parentElement;
    const dropdownContainer = triggerBtn.parentElement;
    const targetContainer = Array.from(dropdownContainer.children).filter(
      (child) => child.dataset.dropdownId === "timepickerContainer",
    )[0];

    // Hide previous dropdown if any
    if (this.dropdown) {
      this.dropdown.hide();
      this.toggleDropdown(this.dropdown._options.rowId, false);
    }

    if (!triggerBtn.dataset.tsid) {
      const durationContainer = triggerBtn.querySelector(
        "[data-dropdown-id='durationContainer']",
      );
      const saveTimeButton = Array.from(targetContainer.children).filter(
        (child) => child.dataset.dropdownId === "saveTimeButton",
      )[0];
      const rowId = Number.parseInt(triggerAction.timeStamp, 10);

      // Set the data-tsid attribute for all the elements
      triggerBtn.dataset.tsid = rowId;
      dropdownContainer.dataset.tsid = rowId;
      durationContainer.dataset.tsid = rowId;
      targetContainer.dataset.tsid = rowId;
      saveTimeButton.dataset.tsid = rowId;

      const options = {
        placement: "bottom",
        triggerType: "click",
        offsetSkidding: 0,
        offsetDistance: 10,
        rowId,
        delay: 300,
        ignoreClickOutsideClass: false,
      };

      this.dropdown = new Dropdown(targetContainer, triggerBtn, options);
    }
    // Show the dropdown
    this.dropdown.show();
    this.toggleDropdown(targetContainer.dataset.tsid, true);
  }

  save_duration(triggerAction) {
    // TODO: get this to run when row is duplicated
    const rowId = triggerAction.target.dataset.tsid;
    const durationContainer = document.querySelector(
      `[data-tsid='${rowId}'] div[data-dropdown-id='durationContainer']`,
    );
    const startTimeInput = document.querySelector(
      `[data-tsid='${rowId}'] input[id$='start_time']`,
    );
    const endTimeInput = document.querySelector(
      `[data-tsid='${rowId}'] input[id$='end_time']`,
    );
    // Hide the dropdown on save time button click
    this.dropdown.hide();
    this.toggleDropdown(rowId, false);

    // Set the duration value in the duration container
    const timeDisplay = `${this.formatTime(startTimeInput.value)} - ${this.formatTime(endTimeInput.value)}`;
    if (startTimeInput.value && endTimeInput.value) {
      durationContainer.innerHTML = timeDisplay;
    }
  }
}
