import toastr from 'toastr';

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: 300,
  hideDuration: 500,
  timeOut: 2000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

export const displayToastMessage = (messages) => {
  toastr.remove();
  if ((messages?.notice || '').length > 0) {
    toastr.success(messages.notice);
  }

  if ((messages?.alert || '').length > 0) {
    toastr.info(messages.alert);
  }

  if ((messages?.error || '').length > 0) {
    toastr.error(messages.error);
  }

  if ((messages?.warning || '').length > 0) {
    toastr.warning(messages.warning);
  }
};

document.addEventListener('toastr:display', (e) => {
  displayToastMessage(e.detail);
});

const initGlobalToast = () => {
  const notice = document.querySelector('#global-toast-notice');
  if (notice) {
    displayToastMessage({ notice: notice.innerHTML });
  }

  const jsNotice = document.querySelector('#global-toast-js-notice');
  if (jsNotice) {
    displayToastMessage({ notice: jsNotice.innerHTML });
  }

  const info = document.querySelector('#global-toast-info');
  if (info) {
    displayToastMessage({ alert: info.innerHTML });
  }

  const infoAlert = document.querySelector('#global-toast-alert');
  if (infoAlert) {
    displayToastMessage({ alert: infoAlert.innerHTML });
  }

  const jsAlert = document.querySelector('#global-toast-js-alert');
  if (jsAlert) {
    displayToastMessage({ alert: jsAlert.innerHTML });
  }

  const error = document.querySelector('#global-toast-error');
  if (error) {
    displayToastMessage({ error: error.innerHTML });
  }
};

document.addEventListener('DOMContentLoaded', initGlobalToast);

window.toastr = toastr;
export default toastr;
