import $ from 'jquery';

export const initStateSelector = (stateDropdown, countryCode, callback) => {
  const stateText = stateDropdown.parent().children('div[class="text"]');
  $.getJSON(`/states/${countryCode}`, (data) => {
    stateDropdown.empty();
    stateText.empty();

    if (Object.keys(data).length === 0) {
      const option = '<option value="unknow">No States Available</option>';
      stateDropdown.append(option);

      if (stateDropdown.parents('.dropdown').length === 1) {
        $(stateDropdown.parents('.dropdown')[0]).addClass('disabled');
      }
    } else {
      if (stateDropdown.parents('.dropdown').length === 1) {
        $(stateDropdown.parents('.dropdown')[0]).removeClass('disabled');
      }

      $.each(data, (value, text) => {
        const option = `<option value="${value}">${text}</option>`;
        stateDropdown.append(option);
      });
    }

    if (callback && typeof (callback) === 'function') {
      callback(data);
    }
  });
};

export const initMappedCountyState = (countryEl) => {
  countryEl.addEventListener('change', () => {
    const stateDropdown = document.querySelector(countryEl.dataset.countryStateSelector);
    initStateSelector($(stateDropdown), countryEl.value);
  });
};

const init = () => {
  document.querySelectorAll('[data-country-state-selector]').forEach((countryEl) => {
    initMappedCountyState(countryEl);
  });
};
document.addEventListener('DOMContentLoaded', init);
document.addEventListener('stateselector:init', init);

window.addStateFeature = initStateSelector;
