import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row", "data", "fold", "unfold"];

  toggle() {
    this.dataTarget.classList.toggle("opened");
    this.rowTarget.classList.toggle("opened");
    this.foldTarget.classList.toggle("tw-hidden");
    this.unfoldTarget.classList.toggle("tw-hidden");
  }
}
