import { Controller } from "@hotwired/stimulus";
import { Drawer } from "flowbite";

export default class extends Controller {
  static targets = ["drawer"];

  showDrawer = (e) => {
    e.preventDefault();
    this.drawer.show();
  };

  hideDrawer = (e) => {
    e.preventDefault();
    this.drawer.hide();
  };

  drawerOptions = {
    placement: "right",
    backdrop: true,
    bodyScrolling: true,
    edge: false,
    edgeOffset: "",
    backdropClasses: "tw-bg-gray-900/50 tw-fixed tw-inset-0 tw-z-40",
    onHide: () => {
      this.drawerTarget.classList.add("tw-translate-x-full");
      this.drawerTarget.classList.remove("tw-transform-none");
    },
    onShow: () => {
      this.drawerTarget.classList.add("tw-transform-none");
      this.drawerTarget.classList.remove("tw-translate-x-full");
    },
  };

  drawer = new Drawer(this.drawerTarget, this.drawerOptions);
}
