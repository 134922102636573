import $ from "jquery";

export const initSemanticPopups = () => {
  $(".popup:not(.click):not(.ui):not(.custom)").popup({
    inline: true,
    position: "top left",
  });

  $(".popup.click").popup({
    on: "click",
    inline: true,
    position: "top left",
  });
};

export const initSemanticDropdown = (isRefresh) => {
  const options = {
    allowCategorySelection: true,
    refresh: isRefresh,
  };

  $(".dropdown:not(.custom)").each((ind, el) => {
    const element = $(el);
    const toTop = element.hasClass("toTop");
    const thisOptions = options;

    thisOptions.direction = toTop ? "upward" : "auto";
    element.dropdown(thisOptions);

    element.find(".remove.icon").on("click", (e) => {
      e.stopPropagation();
      $(e.target).parent(".dropdown").dropdown("clear");
    });
  });

  $(".dropdown.custom.clearable").each((ind, el) => {
    const element = $(el);
    const toTop = element.hasClass("toTop");
    const thisOptions = options;
    thisOptions.direction = toTop ? "upward" : "auto";
    thisOptions.clearable = true;
    thisOptions.placeholder = "";

    element.dropdown(thisOptions);

    element.find(".remove.icon").on("click", (e) => {
      e.stopPropagation();
      $(e.target).parent(".dropdown").dropdown("clear");
    });
  });
};

const SemanticUI = {
  init(isRefresh) {
    initSemanticPopups();
    initSemanticDropdown(isRefresh);

    $("select.currency").on("change", (e) => {
      const currencyLabelSelector = $(e.target).data("currency-label-selector");
      if (currencyLabelSelector) {
        const currencyLabel = $(currencyLabelSelector);
        if (currencyLabel) {
          const symbol = $("option:selected", e.target).data("currency-symbol");
          currencyLabel.text(symbol);
        }
      }
    });

    $(".message .close").on("click", (e) => {
      $(e.target).closest(".message").fadeOut(300);
    });

    $(".ui.icon.checkbox").checkbox({
      onChecked() {
        const container = $(this).parent();
        const checkedIcon = container.attr("data-checked");
        const uncheckedIcon = container.attr("data-unchecked");
        const siblings = $(this).attr("data-siblings");
        $(`input[data-siblings=${siblings}]`).prop("checked", true);
        $(`input[data-siblings=${siblings}]`).parent().addClass("checked");
        $(`input[data-siblings=${siblings}]`)
          .parent()
          .find("i.icon")
          .removeClass(uncheckedIcon)
          .addClass(checkedIcon);
      },
      onUnchecked() {
        const container = $(this).parent();
        const checkedIcon = container.attr("data-checked");
        const uncheckedIcon = container.attr("data-unchecked");
        const siblings = $(this).attr("data-siblings");
        $(`input[data-siblings=${siblings}]`).prop("checked", false);
        $(`input[data-siblings=${siblings}]`).parent().removeClass("checked");
        $(`input[data-siblings=${siblings}]`)
          .parent()
          .find("i.icon")
          .removeClass(checkedIcon)
          .addClass(uncheckedIcon);
      },
    });

    $(".menu .item_close").on("click", (e) => {
      const menuId = $(e.target).attr("data-menu-id");
      if (menuId) {
        const $menu = $(`.menu[data-id=${menuId}]`);
        $menu.find(".opened").removeClass("opened");
        $menu.find(".active").removeClass("active");
        $menu.siblings(".ui.tab.active").removeClass("active");
      }
    });
  },
};

export const initializeSemanticUI = (isRefresh) => {
  SemanticUI.init(isRefresh);
  document.dispatchEvent(new Event("semanticui:initialized"));
};
window.initializeSemanticUI = initializeSemanticUI;

document.addEventListener("semantic:init", () => {
  initializeSemanticUI();
});
