import { Controller } from "@hotwired/stimulus";
import { destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = ["venue", "url"];

  removeVenue(event) {
    event.preventDefault();
    this.ajaxVenueRemover(this.urlTarget.dataset.url);
  }

  async ajaxVenueRemover(url) {
    const response = await destroy(url, {});
    if (response.ok) {
      this.venueTarget.remove();
      const trigger = new CustomEvent("remove-bid-groups");
      window.dispatchEvent(trigger);
    }
  }
}
