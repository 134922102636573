import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "field"];

  toggleField() {
    if (this.checkboxTarget.checked) {
      this.fieldTarget.classList.remove("tw-hidden");
      this.fieldTarget.parentElement.classList.remove("tw-hidden");
    } else {
      this.fieldTarget.classList.add("tw-hidden");
      this.fieldTarget.parentElement.classList.add("tw-hidden");
    }
  }

  connect() {
    this.toggleField();
  }
}
