import { Controller } from "@hotwired/stimulus";
import { i18n } from "../src/custom/Internationalization";

export default class extends Controller {
  static targets = ["control", "conditionalQuestion"];

  connect() {
    if (this.controlTargets.length > 0) {
      this.controlTargets.forEach((control) => {
        if (control.nodeName === "SELECT") {
          this.enableChildren(control.value, false);
          this.disableChildren(control.options, control.value);
        }
      });
    }
    document.dispatchEvent(new Event("rebind-validation"));
  }

  subQuestionDisplay(event) {
    const select = event.currentTarget;
    this.enableChildren(select.value, false);
    this.disableChildren(select.options, select.value);
    document.dispatchEvent(new Event("rebind-validation"));
  }

  enableChildren(answerId, clearValue) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentAnswerId === answerId) {
        question.classList.remove("tw-hidden");
        question.classList.remove("disabled");
        if (clearValue === true) {
          this.clearField(question);
        }

        this.setRequired(question);

        question
          .querySelectorAll("[data-conditionally-required]")
          .forEach((field) => {
            this.setRequired(field);
          });
      }
    });
  }

  setRequired(field) {
    if (field.dataset.conditionallyRequired === "true") {
      field.dataset.formValidate = true;
      if (field.getAttribute("type") !== "checkbox") {
        field.dataset.formValidateRequired = true;
      }
      field.dataset.justValidateFallbackDisabled = false;
    }
  }

  setNotRequired(field) {
    field.dataset.justValidateFallbackDisabled = true;
    field.dataset.formValidate = false;
    if (field.getAttribute("type") !== "checkbox") {
      field.dataset.formValidateRequired = false;
    }
  }

  disableDescendents(parentId) {
    this.conditionalQuestionTargets.forEach((question) => {
      if (question.dataset.parentIds.includes(parentId)) {
        question.classList.add("tw-hidden");
        question.classList.add("disabled");
        this.clearField(question);
        this.setNotRequired(question);

        question
          .querySelectorAll("[data-form-validate-required]")
          .forEach((field) => {
            this.setNotRequired(field);
          });
      }
    });
  }

  disableChildren(options, selected) {
    const optionsArray = Array.from(options);
    if (optionsArray) {
      optionsArray.forEach((option) => {
        if (option.value && option.value !== selected) {
          this.conditionalQuestionTargets.forEach((select) => {
            if (select.dataset.parentAnswerId === option.value) {
              this.disableDescendents(select.dataset.questionId);

              select.classList.add("tw-hidden");
              select
                .querySelectorAll(".conditional-question")
                .forEach((question) => {
                  this.clearField(question);
                  this.setNotRequired(question);

                  question
                    .querySelectorAll("[data-form-validate-required]")
                    .forEach((field) => {
                      this.setNotRequired(field);
                    });
                });
            }
          }, this);
        }
      });
    }
  }

  clearField(field) {
    if (
      field.tagName.toLowerCase() === "input" &&
      field.getAttribute("type") !== "checkbox" &&
      field.getAttribute("type") !== "radio"
    ) {
      field.value = null;
    } else if (field.tagName.toLowerCase() === "div") {
      const selectField = field.querySelector("select");
      if (selectField != null) {
        selectField.value = null;
        selectField.selectedIndex = -1;
      }
      const textElement = field.querySelector(".text");
      if (textElement != null) {
        textElement.innerText = i18n.t("placeholders.please_select");
      }
    } else {
      field.checked = false;
    }
  }
}
