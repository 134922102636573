import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  clickButton(event) {
    this.buttonTargets.forEach((button) => {
      button.classList.remove("tw-border-groupize-green");
      button.classList.add("tw-border-blue-grey-50");
    });
    event.currentTarget.classList.add("tw-border-groupize-green");
    event.currentTarget.classList.remove("tw-border-blue-grey-50");
    event.currentTarget.querySelector("input").checked = "checked";
  }

  connect() {
    this.buttonTargets.forEach((button) => {
      if (button.querySelector("input").checked) {
        button.classList.add("tw-border-groupize-green");
        button.classList.remove("tw-border-blue-grey-50");
      } else {
        button.classList.remove("tw-border-groupize-green");
        button.classList.add("tw-border-blue-grey-50");
      }
    });
  }
}
