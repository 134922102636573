/* eslint-disable no-new */
// This file is a wrapper for the flowbite dropdown
// It allows dynamic rebinding via stimulus when used through Turbo.
// Instead of the implicit initialization.

/* eslint-disable no-underscore-dangle */
import { Controller } from "@hotwired/stimulus";
import { Dropdown } from "flowbite";

export default class extends Controller {
  static targets = ["trigger", "dropdown"];

  connect() {
    // Flowbite doesnt seem to rebind options passed as data attributes when
    // dynamically rebinding the dropdown. So we need to manually pass the options
    new Dropdown(this.dropdownTarget, this.triggerTarget, {
      triggerType: this.triggerTarget.dataset.dropdownTrigger || "click",
      placement: this.triggerTarget.dataset.dropdownPlacement || "bottom-start",
    });
  }

  disconnect() {
    // eslint-disable-next-line no-undef
    const dropdowns = FlowbiteInstances.getInstances("Dropdown");
    const existingDropdown = dropdowns[this.dropdownTarget.id];
    if (existingDropdown) {
      existingDropdown.destroyAndRemoveInstance();
    }
  }
}
