import { Controller } from "@hotwired/stimulus";
import { initPopup } from "../src/plugins/Popup";

export default class extends Controller {
  static targets = ["popup", "button"];

  connect() {
    initPopup({
      detail: {
        selector: this.buttonTarget,
        options: {
          position: "bottom center",
          popup: this.popupTarget,
          lastResort: "bottom left",
          on: "click",
        },
      },
    });
  }
}
