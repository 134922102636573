import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "meetingSection",
    "meetingInput",
    "sleepingSection",
    "sleepingInput",
  ];

  toggleMeetingSection() {
    this.toggleSections(
      this.meetingInputTarget.value,
      this.meetingSectionTargets,
    );
  }

  toggleSleepingSection() {
    this.toggleSections(
      this.sleepingInputTarget.value,
      this.sleepingSectionTargets,
    );
  }

  toggleSections(value, sections) {
    if (value > 0) {
      sections.forEach((section) => {
        this.unhideSection(section);
      });
    } else {
      sections.forEach((section) => {
        this.hideSection(section);
      });
    }
  }

  unhideSection(section){
    if (section.classList.contains("tw-hidden")) {
      section.classList.remove("tw-hidden");
    }
  }

  hideSection(section){
    if (!section.classList.contains("tw-hidden")) {
      section.classList.add("tw-hidden");
    }
  }
}
