import FormSwitcherController from "../form_switcher_controller"
import { post, get } from '@rails/request.js'

export default class extends FormSwitcherController {
  static targets = ["startdate", "enddate", "single_room", "double_room", "suites"];

  connect() {
    super.rebindUI();
  }

  toggleCreateForm(event) {
    event.preventDefault();
    const startDate = new Date(this.startdateTarget.value);
    const endDate = new Date(this.enddateTarget.value);
    const singles = this.single_roomTarget.value;
    const doubles = this.double_roomTarget.value;
    const suites = this.suitesTarget.value;
    const dates = [];
    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    };
    const data = {
      dates: dates,
      single_room_count: singles,
      double_room_count: doubles,
      suites_count: suites
    }
    super.turboPostStreamLoader(event.currentTarget.dataset.url, data);
    super.rebindUI();
  }
}
