import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["group", "venue"];

  removeBidGroup(event) {
    if(this.venueTargets.length == 0) {
      this.groupTarget.remove();
    }
  }
}
