import $ from "jquery";

const modalOptions = {
  closable: false,
  observeChanges: true,
  autofocus: false,
  selector: {
    close: ".close",
  },
};

export const showModal = (selector, callback, options = {}) => {
  const modal = document.querySelector(selector);
  if (!modal) {
    return;
  }

  $(selector)
    .modal(modalOptions)
    .modal(
      "setting",
      Object.assign(options, {
        duration: 200,
        onShow() {
          if (callback && typeof callback === "function") {
            callback();
          }
        },
      }),
    )
    .modal("show")
    .modal("refresh");
  setTimeout(() => {
    $(selector).modal("refresh");
  }, 500);
  modal.classList.remove("hide");
  modal.removeAttribute("hidden");
};
window.showModal = showModal;

export const hideModal = (selector) => {
  $(selector).modal("hide");
};
window.hideModal = hideModal;

const init = () => {
  document.querySelectorAll("[data-modal-display-selector]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      showModal(el.dataset.modalDisplaySelector);
      if (el.dataset.modalHideSelector) {
        hideModal(el.dataset.modalHideSelector);
      }
    });
  });

  document.querySelectorAll("[data-modal-hide-selector]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      hideModal(el.dataset.modalHideSelector);
      if (el.dataset.modalDisplaySelector) {
        showModal(el.dataset.modalDisplaySelector);
      }
    });
  });

  document.querySelectorAll("[data-modal-display-on-load]").forEach((el) => {
    showModal(`#${el.id}`);
  });
};
document.addEventListener("DOMContentLoaded", init);
document.addEventListener("modal:rebind", init);
document.addEventListener("modal:show", (e) => {
  showModal(e.detail.selector);
});
document.addEventListener("modal:close", (e) => {
  hideModal(e.detail.selector);
});
