import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Close the legacy modal when this is invoked from edit attendee
    document.dispatchEvent(
      new CustomEvent("modal:close", { detail: { selector: "#modal_view" } }),
    );
  }

  fade(event) {
    const elementToRemove = document.getElementById(event.target.target);
    if (elementToRemove) {
      const { streamExitClass } = elementToRemove.dataset;
      if (streamExitClass) {
        // Intercept the removal of the element
        event.preventDefault();
        elementToRemove.classList.add(streamExitClass);
        // Wait for its animation to end before removing the element
        elementToRemove.addEventListener("animationend", () => {
          event.target.performAction();
        });
      }
    }
  }
}
