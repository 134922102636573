const silverGMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const initMap = (id = "map") => {
  try {
    const mapEl = document.getElementById(id);
    if (
      mapEl == null ||
      mapEl.length === 0 ||
      typeof window.google === "undefined" ||
      typeof window.google.maps === "undefined"
    )
      return;

    const lng = mapEl.dataset.lng || 0;
    const lat = mapEl.dataset.lat || 0;
    const { title } = mapEl.dataset;

    const myLatLng = new window.google.maps.LatLng(lat, lng);

    const map = new window.google.maps.Map(document.getElementById(id), {
      center: myLatLng,
      scrollwheel: false,
      zoom: 14,
    });

    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: myLatLng,
      map,
      title,
    });
  } catch (e) {
    console.log("Error initializing map", e);
  }
};
document.addEventListener("maps:init", (e) => {
  initMap(e?.detail?.id);
});

export const initEventsMap = (mapId = "events_map") => {
  const mapEl = document.getElementById(mapId);
  const elData = document.getElementById(`${mapId}_markers`);

  if (
    mapEl == null ||
    mapEl.length === 0 ||
    typeof window.google === "undefined"
  )
    return false;

  const greyMapType = new window.google.maps.StyledMapType(silverGMapStyles, {
    name: "Greyscale",
  });
  const defaultStyle = mapEl.dataset.mapStyle || "roadmap";
  const markers = [];

  elData.querySelectorAll("var").forEach((el) => {
    markers.push({
      brand: el.dataset.brand,
      name: el.dataset.name,
      lng: el.dataset.lng,
      lat: el.dataset.lat,
      text: el.dataset.text,
      address: el.dataset.address,
      link: el.dataset.url,
      icon: el.dataset.image,
    });
  });

  const lng = mapEl.dataset.lng || 0;
  const lat = mapEl.dataset.lat || 0;
  const zoom = parseInt(mapEl.dataset.mapZoom || "11", 10);
  const myLatLng = new window.google.maps.LatLng(lat, lng);

  const map = new window.google.maps.Map(document.getElementById(mapId), {
    center: myLatLng,
    scrollwheel: false,
    zoom,
    mapTypeControlOptions: {
      mapTypeIds: ["roadmap", "satellite", "greyscale"],
    },
  });

  map.mapTypes.set("greyscale", greyMapType);
  map.setMapTypeId(defaultStyle);

  const bounds = new window.google.maps.LatLngBounds();

  markers.forEach((marker) => {
    const contentString1 = `
      <div class="ui basic segment">
        <h3>
          <a href="${marker.link}" target="_blank">${marker.name}</a>
          <br />
          <small class="tw-font-semibold">${marker.address}</small>
        </h3>
        <p>${marker.text}</p>
      </div>`;

    const contentString2 = `
      <div class="ui basic segment">
        <img alt="Groupize" src="/images/groupize-logo-dark.png" class="marker_brand_logo">
      </div>`;

    const contentString =
      marker.brand === "1" ? contentString2 : contentString1;

    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
    });

    const LatLng = new window.google.maps.LatLng(marker.lat, marker.lng);

    const gmarker = new window.google.maps.Marker({
      position: LatLng,
      map,
      title: marker.name,
      icon: marker.icon,
    });

    gmarker.addListener("click", () => {
      infowindow.open(map, gmarker);
    });

    bounds.extend(gmarker.getPosition());
  });

  if (markers.length > 1) {
    map.fitBounds(bounds);
  } else if (markers.length === 1) {
    map.setCenter(bounds.getCenter());
    map.setZoom(zoom);
  }

  return map;
};
document.addEventListener("maps:initevents", (e) => {
  initEventsMap(e?.selector);
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-map]").forEach((el) => {
    initMap(el.id);
  });
});
