/* eslint-disable no-new */
// This file is a wrapper for the flowbite popover
// It allows dynamic rebinding via stimulus when used through Turbo.
// Instead of the implicit initialization.

/* eslint-disable no-underscore-dangle */
import { Controller } from "@hotwired/stimulus";
import { Popover } from "flowbite";

export default class extends Controller {
  static targets = ["trigger", "popover"];

  connect() {
    // Flowbite doesnt seem to rebind options passed as data attributes when
    // dynamically rebinding the popover. So we need to manually pass the options
    this.popover = new Popover(this.popoverTarget, this.triggerTarget, {
      triggerType: this.triggerTarget.dataset.popoverTrigger || "click",
      placement: this.triggerTarget.dataset.popoverPlacement || "bottom",
    });

    // Flowbite uses visibility and opacity instead of hidden
    // as a result the popover expands the page until its done initializing
    // defaulting to hidden, then removing it after init solves this.
    this.popoverTarget.classList.remove("tw-hidden");
  }

  disconnect() {
    // eslint-disable-next-line no-undef
    const popovers = FlowbiteInstances.getInstances("Popover");
    const existingPopover = popovers[this.popoverTarget.id];
    if (existingPopover) {
      existingPopover.destroyAndRemoveInstance();
    }
  }
}
