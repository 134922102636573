import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["amount"];

  addCurrencySymbol(event) {
    const index = event.target.selectedIndex;
    const symbol = event.target.options[index].dataset.currencySymbol;
    this.amountTarget.innerHTML = symbol;
  }

  connect() {}
}
