import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.element.dataset.showOnLoad === "true") {
      this.element.showModal();
    }
  }

  close(event) {
    event.preventDefault();
    this.element.close();

    if (this.element.dataset.clearParentOnClose === "true") {
      const frame = this.element.parentNode;

      frame.innerHTML = "";
      frame.removeAttribute("src");
      frame.removeAttribute("complete");
    }

    this.dispatch("close", { target: this.element });
  }

  hide() {
    this.element.close();
    this.dispatch("hide", { target: this.element });
  }

  show() {
    this.element.showModal();
    this.dispatch("show", { target: this.element });
  }
}
