import { Controller } from "@hotwired/stimulus";
import { Popover } from "flowbite";
import { i18n } from "../../src/custom/Internationalization";

export default class extends Controller {
  static targets = [
    "popover",
    "target_attendance",
    "ta_display",
    "estimated_sleeping_rooms_number",
    "rooms_display",
  ];

  toggleClass = (el, input, defaultClasses, activeClasses) => {
    if (input.value) {
      el.classList.add(activeClasses);
      el.classList.remove(defaultClasses);
    } else {
      el.classList.remove(activeClasses);
      el.classList.add(defaultClasses);
    }
  };

  updateDisplay = (displayTarget, inputTarget, displayVar) => {
    displayTarget.innerHTML = inputTarget?.value
      ? `${inputTarget.value} ${displayVar}`
      : `# ${displayVar}`;
    this.toggleClass(
      displayTarget,
      inputTarget,
      "tw-text-blue-grey-500",
      "tw-text-blue-grey-900",
    );
  };

  popOptions = {
    placement: "bottom",
    triggerType: "click",
    offset: 0,
    onHide: (e) => {
      if (this.popoverTarget.contains(document.activeElement)) {
        e.show();
        return;
      }

      this.popoverTarget.classList.add(
        "tw-hidden",
        "tw-opacity-0",
        "tw-invisible",
      );
      this.popoverTarget.classList.remove("tw-opacity-100", "tw-visible");
      this.updateDisplay(
        this.ta_displayTarget,
        this.target_attendanceTarget,
        i18n.t("attendee_type.global_default"),
      );
      this.updateDisplay(
        this.rooms_displayTarget,
        this.estimated_sleeping_rooms_numberTarget,
        i18n.t("meeting_request_form.sections.sleeping_rooms"),
      );
    },
    onShow: () => {
      this.popoverTarget.classList.add("tw-opacity-100", "tw-visible");
      this.popoverTarget.classList.remove(
        "tw-hidden",
        "tw-opacity-0",
        "tw-invisible",
      );
    },
  };

  popover = new Popover(this.popoverTarget, this.element, this.popOptions);

  popoverTrigger() {
    this.popover.show();
  }

  initialize() {
    this.updateDisplay(
      this.ta_displayTarget,
      this.target_attendanceTarget,
      i18n.t("attendee_type.global_default"),
    );
    this.updateDisplay(
      this.rooms_displayTarget,
      this.estimated_sleeping_rooms_numberTarget,
      i18n.t("meeting_request_form.sections.sleeping_rooms"),
    );
  }
}
