import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"];

  clickButton() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    this.toggleClasses();
  }

  toggleClasses() {
    if (this.checkboxTarget.checked) {
      this.buttonTarget.classList.add("tw-border-groupize-green");
      this.buttonTarget.classList.remove("tw-border-blue-grey-50");
    } else {
      this.buttonTarget.classList.remove("tw-border-groupize-green");
      this.buttonTarget.classList.add("tw-border-blue-grey-50");
    }
  }

  connect() {
    this.toggleClasses();
  }
}
