import { Controller } from "@hotwired/stimulus";
import { initDatePicker } from "../src/custom/DatetimeInit";
import { initializeSemanticUI } from "../src/plugins/SemanticUI";

export default class extends Controller {
  static targets = ["template", "selectbox"];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
    this.rebindUI();
  }

  add_association(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.templateTarget.insertAdjacentHTML("afterend", content);
    this.rebindUI();
  }

  copy_associations(event) {
    event.preventDefault();
    const selectedCheckboxes = this.selectboxTargets.filter(
      (checkbox) => checkbox.checked,
    );
    selectedCheckboxes.forEach((checkbox) => {
      const originalElement = checkbox.closest(`.${this.wrapperClass}`);
      const { variableName } = originalElement.dataset;
      this.copy_record(originalElement, variableName);
    });
  }

  copy_association(event) {
    event.preventDefault();
    const originalElement = event.target.closest(`.${this.wrapperClass}`);
    const { variableName } = originalElement.dataset;
    this.copy_record(originalElement, variableName);
  }

  copy_record(originalElement, variableName) {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    const wrapper = document.createElement("div");
    wrapper.innerHTML = content;

    const clonedElement = wrapper.firstElementChild;
    const originalFormFields = originalElement.querySelectorAll(
      `[name^="${variableName}"]`,
    );
    const clonedFormFields = clonedElement.querySelectorAll(
      `[name^="${variableName}"]`,
    );

    originalFormFields.forEach((originalField, index) => {
      const clonedField = clonedFormFields[index];
      if (/\[id\]/.test(originalField.name)) {
        clonedField.value = "";
      } else {
        clonedField.value = originalField.value;
      }
    });

    clonedFormFields.forEach((field) => {
      const originalName = field.getAttribute("name");
      const newName = originalName.replace(
        new RegExp(`${variableName}\\[\\d+\\]`),
        `${variableName}[${new Date().getTime()}]`,
      );
      field.setAttribute("name", newName);
      field.setAttribute(
        "id",
        field.getAttribute("id").replace(/\d+/, new Date().getTime()),
      );
    });
    originalElement.parentElement.insertBefore(
      clonedElement,
      originalElement.nextSibling,
    );
    this.rebindUI();
  }

  setDateField(wrapper, date, dateFieldName) {
    const dateSelector = `input[name*=${dateFieldName}]`;
    const dateField = wrapper.querySelector(dateSelector);
    dateField.value = date.toISOString().split("T")[0];
  }

  setTimes(wrapper) {
    const startTimeField = wrapper.querySelector(`input[name*="start_time"]`);
    const endTimeField = wrapper.querySelector(`input[name*="end_time"]`);
    startTimeField.value = "00:00";
    endTimeField.value = "23:59";
  }

  setAttendance(wrapper, fieldName, attendance) {
    const attendanceField = wrapper.querySelector(
      `input[name*="${fieldName}"]`,
    );
    attendanceField.value = attendance;
  }

  remove_association(event) {
    event.preventDefault();
    const wrapper = event.target.closest(`.${this.wrapperClass}`);
    this.remove_record(wrapper);
  }

  rebindUI() {
    document.dispatchEvent(new Event("rebind-validation"));
    initDatePicker();
    initializeSemanticUI();
  }

  remove_associations(event) {
    event.preventDefault();
    const selectedCheckboxes = this.selectboxTargets.filter(
      (checkbox) => checkbox.checked,
    );
    selectedCheckboxes.forEach((checkbox) => {
      const wrapper = checkbox.closest(`.${this.wrapperClass}`);
      this.remove_record(wrapper);
    });
  }

  remove_record(wrapper) {
    if (wrapper.dataset.newRecord === "true") {
      wrapper.querySelector(`[name^=bulk_selections]`).checked = false;
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.querySelector(`[name^=bulk_selections]`).checked = false;
      wrapper.style.display = "none";
    }
  }

  highlight_selected_row(event) {
    const selectedRow = event.target.closest(".nested-fields");
    if (event.target.checked) {
      selectedRow.classList.add("selected");
    } else {
      selectedRow.classList.remove("selected");
    }
  }
}
