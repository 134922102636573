import FormSwitcherController from "../form_switcher_controller"
import { post, get } from '@rails/request.js'

export default class extends FormSwitcherController {
  static targets = ["option", "startdate", "enddate"];

  connect() {
    super.rebindUI();
  }

  toggleCreateForm(event) {
    event.preventDefault();
    const roomType = this.optionTarget.value;
    const startDate = new Date(this.startdateTarget.value);
    const endDate = new Date(this.enddateTarget.value);
    const dates = [];
    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    };
    const attendance = document.getElementById('bid_request_event_attributes_target_attendance').value;
    const data = {
      attendance: attendance,
      roomType: roomType,
      dates: dates
    }
    super.turboPostStreamLoader(event.currentTarget.dataset.url, data);
    super.rebindUI();
  }
}
