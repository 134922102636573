import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "section"];

  scrollToSection() {
    let current = "";
    this.sectionTargets.forEach((section) => {
      const fixedHeader = document.querySelector(".header")?.offsetHeight || 0;
      const sectionTop =
        window.innerWidth === 768
          ? section.offsetTop - fixedHeader
          : section.offsetTop;
      if (window.scrollY >= sectionTop) {
        current = section.getAttribute("id");
      }
    });

    if (current === "") {
      current = this.sectionTargets[0]?.getAttribute("id");
    }

    this.linkTargets.forEach((a) => {
      a.classList.remove("tw-text-brand-500");
      a.classList.remove("tw-bg-white");
      a.classList.add("tw-text-blue-grey-500");
      const iconComponent = a.querySelector(".svg-icon-component");
      if (iconComponent) {
        iconComponent.classList.remove("tw-text-brand-500");
        iconComponent.classList.add("tw-text-blue-grey-500");
      }
      if (a.getAttribute("href") === `#${current}`) {
        a.classList.add("tw-text-brand-500");
        a.classList.add("tw-bg-white");
        if (iconComponent) {
          iconComponent.classList.remove("tw-text-blue-grey-500");
          iconComponent.classList.add("tw-text-brand-500");
        }
      }
    });
  }

  connect() {
    this.scrollToSection();
  }
}
