import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "url"
  ];

  connect() {
    window.location.href = this.urlTarget.dataset.url;
  }
}
