import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter", "input"];

  updateLimit() {
    const limit = parseInt(this.inputTarget.getAttribute("maxlength"), 10);
    const { length } = this.inputTarget.value;
    this.counterTarget.innerText = limit - length;
  }
}
