import { Controller } from "@hotwired/stimulus";
import { post, get } from "@rails/request.js";
import toastr from "toastr";
import { i18n } from "../src/custom/Internationalization";
import { initDatePicker } from "../src/custom/DatetimeInit";
import { initializeSemanticUI } from "../src/plugins/SemanticUI";


export default class extends Controller {
  static targets = [
    "standard",
    "custom",
    "checkbox",
    "optionbox",
    "form",
    "masterForm",
  ];

  rebindUI() {
    document.dispatchEvent(new Event("rebind-validation"));
    initDatePicker();
    initializeSemanticUI();
  }

  getDates() {
    const startDate = new Date(
      document.getElementById("mrf-event-start-date").value,
    );
    const endDate = new Date(
      document.getElementById("mrf-event-end-date").value,
    );
    const dates = [];
    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    };
    return dates;
  }

  toggleForm(event) {
    event.preventDefault();
    this.turboGetStreamLoader(event.currentTarget.dataset.url);
    this.rebindUI();
  }

  saveForm(event) {
    event.preventDefault();
    const { form } = event.currentTarget.dataset;
    document.querySelector(form).requestSubmit();
  }

  async validateForm(form) {
    const validationController =
      this.application.getControllerForElementAndIdentifier(
        form,
        "form-validation",
      );
    const validatableFields = Array.from(
      form.querySelectorAll("[data-form-validate]"),
    );
    // Send off fields that need to be validated for valiation
    const validationPromises = validatableFields.map((target) => {
      if (validationController.validator.fieldIds.has(`#${target.id}`)) {
        return validationController.validator.revalidateField(`#${target.id}`);
      }
      // return true if a field is marked for validation but is not required
      return true;
    });
    // Wait for all validations to complete
    const results = await Promise.all(validationPromises);
    return results.every((result) => result === true);
  }

  async saveAllForms(event) {
    event.preventDefault();
    const { url } = event.currentTarget.dataset;
    const formValidPromises = this.formTargets.map(form => {
      return this.validateForm(form);
    });
    const formValidResults = await Promise.all(formValidPromises);
    const allFormsValid = formValidResults.every((result) => result === true);

    // If all forms are valid save them all
    if (allFormsValid) {
      const formPromises = this.formTargets.map((form) => {
        const formData = new FormData(form);
        formData.append("full_save", "true");


        return new Promise((resolve, reject) => {
          this.turboPostStreamLoader(form.action, formData)
            .then((response) => {
              if (response && response.statusCode === 200) {
                resolve(response);
              } else {
                reject(new Error(`Status: ${response.response.statusText}`));
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      });

      try {
        const results = await Promise.allSettled(formPromises);
        let failed = false;
        let errors = "";
        results.forEach((result) => {
          if (result.status === "rejected") {
            failed = true;
            errors += result.reason;
          }
        });
        if (failed) {
          console.error(errors);
        } else {
          this.turboGetStreamLoader(url);
        }
      } catch (error) {
        console.error("Unexpected error:", error);
      }

    } else {
      this.scrollToError();
      toastr.error(i18n.t("meeting_request_form.errors.missing_requirements"));
    }
  }

  scrollToError() {
    const errorElement = document.querySelector(".just-validate-error-label");
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth" });
    }
  }


  async turboPostStreamLoader(url, data) {
    return post(url, { body: data, responseKind: "turbo-stream" });
  }

  async turboGetStreamLoader(url, queryString = "") {
    return get(url, { query: queryString, responseKind: "turbo-stream" });
  }

}
