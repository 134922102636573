import FormSwitcherController from "../form_switcher_controller"
import { post, get } from '@rails/request.js'

export default class extends FormSwitcherController {
  static targets = ["optionbox"];

  connect() {
    super.rebindUI();
  }

  toggleCreateForm(event) {
    event.preventDefault();
    const selectedValues = [];
    this.optionboxTargets.forEach(box => {
      if (box.checked) {
        selectedValues.push(box.value);
      }
    });
    const dates = super.getDates();
    if (dates.length > 0) {
      const data = {
        dates: dates,
        details: selectedValues.join(',')
      };
      super.turboPostStreamLoader(event.currentTarget.dataset.url, data);
    }
    super.rebindUI();
  }
}
