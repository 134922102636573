import { Controller } from "@hotwired/stimulus";
import toastr from "toastr";

export default class extends Controller {
  static targets = ["notice", "alert", "error", "warning"];

  connect() {
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      onclick: null,
      showDuration: 300,
      hideDuration: 500,
      timeOut: 2000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    toastr.remove();

    if (this.hasNoticeTarget) {
      toastr.success(this.noticeTarget.innerHTML);
    }

    if (this.hasAlertTarget) {
      toastr.info(this.alertTarget.innerHTML);
    }

    if (this.hasErrorTarget) {
      toastr.error(this.errorTarget.innerHTML);
    }

    if (this.hasWarningTarget) {
      toastr.warning(this.warningTarget.innerHTML);
    }
  }
}
